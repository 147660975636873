import { FC } from "react";
import {
  styled,
  alpha,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

interface ReadingCellProps {
  data: {
    SensorId: number;
    SensorName: string;
    // sensorMacAddres: string;
    // Data1Enable?: boolean;
    // Data1Name?: string;
    DataValue?: string;
    DataUM?:string;
    // Data2Enable?: boolean;
    // Data2Name?: string;
    // Data2Value?: string;
    // Data2UM?:string;
    // Data3Enable?: boolean;
    // Data3Name?: string;
    // Data3Value?: string;
    // Data3UM?:string;
    // Data4Enable?: boolean;
    // Data4Name?: string;
    // Data4Value?: string;
    // Data4UM?:string;
  };
  onEdit?: () => void;
  onDelete?: () => void;
}

const ReadingSection = styled('div')(({ theme }) => {
  const border = `1px solid ${alpha(theme.palette.primary.main, .5)}`
  return {
    padding: theme.spacing(1),
    borderTop: border,
    "&:last-child": {
      borderBottom: border,
    }
  }
});


const ReadingCell: FC<ReadingCellProps> = ({
  data,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ReadingSection>
      <Box
        display="flex"
        flexDirection={smallScreen ? "row" : "row" }
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography
            variant="h5"
            style={{
              lineHeight: '28px',
            }}
          >
            {data.SensorName}&nbsp;
            {/* <Typography
            variant="caption"
            color="primary"
            style={{
              lineHeight: '28px',
            }}
          >
             ({data.sensorMacAddres})
          </Typography> */}
          </Typography>
        </Box>
       
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            flexDirection={smallScreen ? "row" : "row" }
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                className="role"
                color="primary"
                style={{
                  lineHeight: '28px',
                  textAlign: 'right'
                }}
              >
                {data.DataValue}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                className="role"
                color="primary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'left',
                  marginLeft: '10px'
                }}
              >
                {data.DataUM}
              </Typography>
            </Box>
          </Box>
        </Box>
       
      </Box>
    </ReadingSection>
  );

};

export default ReadingCell;